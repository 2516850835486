.image {
  width: 224px;
  cursor: pointer;
}

.button {
  border: none;
  border-radius: 36px;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: white;
  padding: 20px 48px;
  background: linear-gradient(180deg, rgba(145, 195, 1, 0.8), #91c301);
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
}
