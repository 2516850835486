.container-info {
  width: 100%;
  height: 100%;
  max-width: 360px;
  max-height: 640px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, auto);
  align-content: center;
  justify-items: center;
  gap: 38px;

  background-image: url("bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  border-radius: 16px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
